.App {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}
.titlehead {
  font-size: 12vmin;
  position: relative;
  top: 35vh;
  left: 33vw;
}
.homewrapper {
  flex: 1 0 auto;
  min-height: 100vh;
}
.homewrapper > img {
  width: 100%;
}
.contentbox {
  flex: 1 0 auto;
  display: block;
  position: relative;
  min-height: fit-content;
}
.contentwrapper {
  position: relative;
  min-height: 100vh;
}
footer {
  background: white;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.logo {
  background: white;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 17.5vh;
}
.logo > img {
  height: 20vh;
}
.contacts {
  width: 60%;
  height: 10vh;
  display: flex;
  justify-content: space-around;
}
.contacts > a {
  width: auto;
  height: 100%;
}
.contacts > a > img {
  height: 100%;
  width: auto;
}
.footbot {
  height: 15vh;
  width: 100%;
}
.footbot > div {
  margin: 3vmin 0;
}
.footbot > div * {
  color: white;
  font-size: 1.25vmin;
  line-height: 0.25vmin;
  text-align: center;
}
@media only screen and (max-width: 1180px) {
  .homewrapper {
    min-height: 55vh;
    margin-top: 7.5vh;
  }
  .contacts > a {
    height: 60%;
  }
  .footbot > div * {
    font-size: 2.5vmin;
  }
  .contentwrapper {
    min-height: 55vh;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1180px) {
  .footbot > div * {
    font-size: 1.75vmin;
  }
}