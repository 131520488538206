.mobilenav {
    position: fixed;
    top: 0;
    z-index: 5;
    background: white;
    width: 100%;
    height: 7.5vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
    font-size: 4vmin;
}
.mobilenav > div {
    background: rgb(206, 206, 206);
    font-size: 6vmin;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    color: white;
}
.mobilenav > img {
  height: 10vh;
}
.mobilemenu {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: white;
}
.closer {
    background: rgb(211, 211, 211);
    color: white;
    width: fit-content;
    padding: 0 5px;
    font-size: 7vmin;
    margin: 4vmin;
}
.mobilemenu > ul {
    color: rgb(148, 147, 147);
    list-style-type: none;
    padding: 4vmin;
    font-size: 4vmin;
}
.mobilemenu > ul > li {
    margin-bottom: 3vmin;
}
.mobilesubmenu {
    color: rgb(148, 147, 147);
    list-style-type: none;
    padding-left: 3vmin;
    font-size: 4vmin;
}
@media only screen and (min-width: 768px) {
  .mobilemenu > ul {
    font-size: 2.5vmin;
  }
  .mobilesubmenu {
    font-size: 2.5vmin;
  }
  .closer {
    font-size: 4vmin;
  }
  .mobilenav > div {
    font-size: 4vmin;
  }
}