.artworkWrapper {
    position: relative;
    padding: 5vh 0;
    margin-left: -10vw;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.artworkSliderHolder {
    width: 50vw;
}
@media only screen and (max-width: 1180px) {
    .artworkWrapper {
        margin: unset;
        margin-top: 5vh;
    }
    .artworkSliderHolder {
        width: 100vw;
    }
}