.spotlite {
    position: relative;
    display: inline-block;
    height: 65vh;
    width: 100%;
}
.swiper-container {
    width: 100%;
}
.swiper-slide {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.nonartslide {
    width: fit-content;
}
.swiper-slide > img {
    align-self: center;
    height: 50vh;
    width: auto;
    transition: all 200ms;
}
.swiper-button-next, .swiper-button-prev {
    position: absolute;
    color: rgb(39, 39, 39) !important;
}
.swipeDescription {
    position: relative;
    left: 10vw;
    top: -15vh;
}
.swipeDescription > p {
    margin: 0;
}
@media only screen and (max-width: 1180px) {
    .spotlite {
        height: fit-content;
        margin-bottom: 2vh;
    }
    .swiper-slide {
        width: 100vw;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .swiper-slide > img {
        width: 100vw;
        height: auto;
    }
    .swiper-button-next, .swiper-button-prev {
        z-index: 1;
        color: rgb(39, 39, 39) !important;
    }
    .swipeDescription {
        left: 5vw;
        top: unset;
    }
    .navshrinker > .swiper-container > .swiper-button-next, 
    .navshrinker > .swiper-container > .swiper-button-prev   {
        margin-top: -22.5vh;
    }
}