.lookbookwrapper {
    position: relative;
    z-index: 0;
    padding: 0 0 5vh 0;
}
.lookbookwrapper > h1 {
    color: #E7E4EB;
    padding-left: 15%;
    margin: 0 0 2vh 0;
    font-size: 3vmin;
}
.paraholder {
    position: relative;
    padding-left: 25%;
    width: 50%;
    margin-bottom: 25vh;
    color: #999999;
}
.lookbookwrapper > p {
    color: #999999;
    font-size: 1vmin;
    position: relative;
    width: 50%;
    padding-left: 15%;
    margin: -1vh 0 1vh 0;
}
@media only screen and (max-width: 1180px) {
    .lookbookwrapper {
        padding: unset;
    }
    .lookbookwrapper > h1 {
        padding-left: 5%;
        margin-top: 10vh;
        font-size: 7vmin;
    }
    .lookbookwrapper > p {
        font-size: 3vmin;
        padding-left: 5%;
    }
    .paraholder {
        width: 80%;
        padding-left: 15%;
        margin-bottom: unset;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1180px) {
    .lookbookwrapper > h1 {
        font-size: 4vmin;
    }
    .lookbookwrapper > p {
        font-size: 1.75vmin;
    }

}