.navbox {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    mix-blend-mode: difference;
    color: white;
}
.navbox > ul {
    width: 75%;
    height: 5vmin;
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.65vmin;
}
.navbox > ul > li {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
    transition: all 200ms ease-in-out;
}
.navbox > ul > li > a {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbox > ul > li:hover {
    color: rgb(255, 128, 217);
}
.navsubmenu {
    position: absolute;
    top: 70%;
    padding: 0;
    list-style-type: none;
    color: gray;
    display: none;
    text-align: center;
}
.navsubmenu > li {
    padding: 2.5px 10px;
    background: white;
}
.navsubmenu > li > a {
    width: 100%;
}
.navdropper:hover .navsubmenu {
    display: block;
}
.navsubmenu:hover {
    display: block;
}
.navsubmenu > li:hover {
    background: black;
    border-bottom: none;
}
.nav2 {
    background: white;
    position: relative;
    height: 25vh;
    mix-blend-mode: normal;
    color: gray;
}
.nav2 > ul > li {
    transition: unset;
}
.nav2 > ul > li > .navsubmenu {
    top: 90%;
}
.nav2 > ul > li:hover {
    color: rgb(148, 147, 147);
}
.nav2 > ul > li:hover {
    color: inherit;
    border-bottom: 1px solid black;
}
.logonav {
    position: absolute;
    top: 0;
}
.logonav > img {
    height: 20vh;
}