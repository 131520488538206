.projectBackground {
    position: fixed;
    background-size: 30%;
    z-index: 0;
    top: -40vh;
    left: 0;
    width: 100vw;
}
.projectWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.projectContent {
    width: 75%;
    margin-top: 49vh;
}
.projectHeader {
    width: 50%;
    margin-left: 7.5vw;
    margin-bottom: 5vh;
}
.projectHeader > h1 {
    font-size: 5vmin;
    margin: 0;
}
.projectHeader > p {
    color: #999999;
}
.imageCardHolder {
    width: 100%;
}
.imageCardHolder > img {
    width: inherit;
    margin-bottom: 2vh;
}
@media only screen and (max-width: 1180px) {
    .projectBackground {
        display: none;
    }
    .projectContent {
        margin-top: 10vh;
        width: 95%;
    }
    .projectHeader {
        width: 80%;
    }
}